import React from "react";
import styled from "styled-components";
import { prefixAttr } from "../../utilities/helpers";

const AccessibleDialog = ({ aria, ...restProps }) => {
  const ariaProps = prefixAttr({ object: aria, prefix: "aria-" });

  return restProps.open ? (
    <dialog
      role="dialog"
      aria-hidden={!restProps.open}
      {...ariaProps}
      {...restProps}
    />
  ) : null;
};

const Dialog = styled(AccessibleDialog)`
  border: 2px solid #333;
  display: none;
  padding: 1em;
  position: fixed;
  top: calc(33vh);
  width: 90%;
  z-index: 101;
  max-width: calc(800px);

  &[open] {
    display: block;

    & ~ .dialog-backdrop {
      display: block;
      background-color: #333;
      height: 100vh;
      opacity: 0.45;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }
  }
`;

export default Dialog;
