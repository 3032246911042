import React from "react";
import styled from "styled-components";
import Button from "./Button";
import Dialog from "./Dialog";
import Grid from "./Grid";
import { spectreLinkResets } from "../../utilities/helpers";

const socialColors = {
  github: "#333",
  linkedin: "#0077b5",
  twitter: "#1da1f2"
};

const SocialLink = styled("a")`
  ${spectreLinkResets};
  color: #fff;
  cursor: pointer;
  ${({ href }) => {
    switch (true) {
      case href.search(/github/g) > -1: {
        return `
				background-color: ${socialColors.github};
				`;
        break;
      }
      case href.search(/linkedin/g) > -1: {
        return `
				background-color: ${socialColors.linkedin};
				`;
        break;
      }
      case href.search(/twitter/g) > -1: {
        return `
				background-color: ${socialColors.twitter};
				`;
        break;
      }
      default: {
        return `
				background-color: #aaa;
				`;
      }
    }
  }};
  padding: 1em 0;
  text-align: center;
  &:hover {
    color: #fff;
  }
  &:active,
  &:focus {
    color: rgba(255, 255, 255, 0.66);
  }
  &:focus {
    box-shadow: none;
    outline: 4px solid rgba(255, 255, 255, 0.66);
    outline-offset: -4px;
  }
`;

const EmailDisplay = styled("p")`
  text-align: center;
`;

const ButtonWrapper = styled("div")`
  text-align: right;
`;

const EmailDialog = ({ open, close }) => (
  <footer>
    <Dialog id="email-dialog" aria={{ label: "email dialog" }} {...{ open }}>
      {open && <EmailDisplay>geoffdavis92@gmail.com</EmailDisplay>}
      <ButtonWrapper>
        <Button role="button" type="button" onClick={close}>
          Close
        </Button>
      </ButtonWrapper>
    </Dialog>
    <div className="dialog-backdrop" onClick={close}>
      <span data-sr-only>Click to close dialog</span>
    </div>
  </footer>
);

class SocialLinks extends React.Component {
  state = { dialogIsOpen: false };
  closeDialog = e => {
    this.setState(prevState => ({
      dialogIsOpen: false
    }));
  };
  render() {
    return (
      <main>
        <Grid
          column_gap={{ 0: 0 }}
          row_gap={{ 0: 0 }}
          template_columns={{ 0: "repeat(1,100%)", 768: "repeat(2,50%)" }}
        >
          <SocialLink
            href="#email-dialog"
            onClick={e => {
              e.preventDefault();
              this.setState(prevState => ({ dialogIsOpen: true }));
            }}
          >
            Email
          </SocialLink>
          <SocialLink
            href="https://twitter.com/gdavis92"
            rel="noopener noreferrer"
            target="_blank"
          >
            Twitter
          </SocialLink>
          <SocialLink
            href="https://github.com/geoffdavis92"
            rel="noopener noreferrer"
            target="_blank"
          >
            Github
          </SocialLink>
          <SocialLink
            href="https://linkedin.com/in/geoffreytdavis"
            rel="noopener noreferrer"
            target="_blank"
          >
            LinkedIn
          </SocialLink>
        </Grid>
        <EmailDialog open={this.state.dialogIsOpen} close={this.closeDialog} />
      </main>
    );
  }
}

export default SocialLinks;
