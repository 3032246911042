import React from "react";
import styled from "styled-components";

const Button = styled("button")`
  background-color: #ca1211;
  border: none;
  border-radius: 2px;
  color: #fff;
  line-height: 1;
  padding: 0.5em;
`;

export default Button;
