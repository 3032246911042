import React from "react"
import styled from "styled-components"
import Page from "../templates/page"
import Grid from "../components/shared/Grid"
import Metadata from "../components/shared/Metadata"
import SocialLinks from "../components/shared/SocialLinks"
import { slugify } from "../utilities/functions"
import { markdownExternalLinks } from "../utilities/helpers"

const UnstyledOL = styled("ol")`
  list-style-type: none;
`

const RoleTitle = styled("p")`
  margin-bottom: 0;
`

const JobCompany = styled("h4")`
  margin-bottom: 0.25em;
`

export default ({
  data: {
    allMarkdownRemark: { edges: markdownPages },
    allDataJson: { edges: authorData },
  },
}) => {
  const [{ html: aboutHTML, frontmatter }] = markdownPages.map(
    ({ node: { html, frontmatter } }) => ({ html, frontmatter })
  )

  const [Geoff] = authorData.map(({ node }) => node.content)
  const [firstName, lastName] = Geoff.name.split(/\s/g)

  const CareerTimelineComponent = Geoff.career.map((job, jobIndex, allJobs) => {
    const jobID = `company-${slugify(job.company)}`
    const lastJob = jobIndex === allJobs.length - 1

    const JobRolesComponent = job.roles.map((role, roleIndex, allRoles) => {
      const lastRole = roleIndex === allRoles.length - 1
      const roleID = `${jobID}-role-${slugify(role.title)}`

      const dateStart = new Date(role.date_start)
        .toLocaleDateString()
        .replace(/\/[0-9]{0,2}\//, "/")

      const dateEnd = role.date_end ? (
        new Date(role.date_end)
          .toLocaleDateString()
          .replace(/\/[0-9]{0,2}\//, "/")
      ) : (
        <i>current role</i>
      )

      const RoleTimelineIconComponent = (
        <a
          href={`#${roleID}`}
          className={`timeline-icon${
            !lastJob ? " icon-lg" : lastRole ? "" : " icon-lg"
          }`}
          aria-label={`Anchor link for ${role.title}${
            lastRole && lastJob ? ", current role" : ""
          }`}
        >
          {lastRole && lastJob ? (
            <span data-sr-only>link to current role</span>
          ) : (
            <i className="icon icon-check">
              <span data-sr-only>link to past role</span>
            </i>
          )}
        </a>
      )

      return (
        <li key={roleID} className="timeline-item" id={roleID}>
          <nav className="timeline-left">{RoleTimelineIconComponent}</nav>
          <div className="timeline-content">
            <RoleTitle>{role.title}</RoleTitle>
            <p>
              <small>
                {dateStart} - {dateEnd}
              </small>
            </p>
          </div>
        </li>
      )
    })

    const JobTimelineIconComponent = (
      <a
        href={`#${jobID}`}
        className={`timeline-icon${
          jobIndex !== allJobs.length - 1 ? " icon-lg" : ""
        }`}
        aria-label={`Anchor link for ${job.company}${
          jobIndex === allJobs.length - 1 ? ", current company" : ""
        }`}
      >
        {jobIndex === allJobs.length - 1 ? (
          <span data-sr-only>link to current company</span>
        ) : (
          <i className="icon icon-check" />
        )}
      </a>
    )

    return (
      <li key={jobID}>
        <article className="timeline-item" id={jobID}>
          <nav className="timeline-left">{JobTimelineIconComponent}</nav>
          <div className="timeline-content">
            <JobCompany>{job.company}</JobCompany>
            <h5 className="text-gray">{job.location}</h5>
            <ol className="timeline">{JobRolesComponent}</ol>
          </div>
        </article>
      </li>
    )
  })

  return (
    <Page id="about" heading={`My name is ${firstName}`}>
      <Metadata {...{ frontmatter }} />
      <Grid
        component="div"
        column_gap={{ 0: 0, 768: "1.5em" }}
        row_gap={{ 0: "1em" }}
        template_columns={{
          0: "repeat(1,100%)",
          768: "60% 40%",
          1024: "70% 30%",
        }}
      >
        <section>
          <div
            dangerouslySetInnerHTML={{
              __html: markdownExternalLinks(aboutHTML),
            }}
          />
        </section>
        <section id="career">
          <header>
            <h2>Career</h2>
          </header>
          <UnstyledOL className="timeline">
            {CareerTimelineComponent}
          </UnstyledOL>
        </section>
      </Grid>
      <div className="divider">
        <span data-sr-only>section divider</span>
      </div>
      <br />
      <section id="contact">
        <header>
          <h2>Contact Me</h2>
          <p>Click the links below to find me on the respective platform.</p>
        </header>
        <SocialLinks />
      </section>
    </Page>
  )
}

export const AboutQuery = graphql`
  query AboutQuery($path: String!) {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: $path } }) {
      edges {
        node {
          html
          frontmatter {
            title
            description
            og_url
            twitter_card
            twitter_creator
          }
        }
      }
    }
    allDataJson(filter: { title: { eq: "author" } }) {
      edges {
        node {
          content {
            name
            email
            twitter
            github
            career {
              company
              location
              roles {
                title
                date_start
                date_end
              }
            }
          }
        }
      }
    }
  }
`
